@import '../../style/constants.scss';

.logo {
  margin: 0;
  text-transform: uppercase;
  width: 300px;

  @media ($wide) {
    width: 92vmin;
  }
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $c-primary;
  font-size: 50px;
  line-height: 1.1;
  margin: 0 0.1em;

  @media ($wide) {
    font-size: 15vmin;
  }
}

.bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $c-secondary;
  font-size: 115px;
  line-height: 0.8;

  @media ($wide) {
    font-size: 35vmin;
  }
}
