@import 'src/client/view/style/constants.scss';

.resultsTable {
  border-collapse: collapse;
  width: 100%;
  font-size: 20px;

  @media ($wide) {
    font-size: 4vh;
  }

  tr:nth-child(2n - 1) {
    background: $c-background-dark;
  }

  td {
    padding: 8px 16px;

    @media ($wide) {
      padding: 1vh 3vh;
    }

    text-align: left;

    &:last-child {
      text-align: right;
    }
  }
}
