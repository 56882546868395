import { isSignal, computed, isolate, onDeactivate, collect } from 'spred';

const creatingState = {
    root: null,
    isCreating: false,
    path: '',
    setupQueue: [],
};
const traversalState = {
    path: '',
    i: 0,
    node: null,
};
const FIRST_CHILD = 'F';
const NEXT_SIBLING = 'N';
const PARENT_NODE = 'P';
const BINDING = 'B';
const START_CHILDREN = '>';
const END_CHILDREN = '<';
function next(fn) {
    const current = traversalState.path[traversalState.i];
    const nextValue = traversalState.path[++traversalState.i];
    const goDeeper = nextValue === START_CHILDREN;
    switch (current) {
        case FIRST_CHILD:
            traversalState.node = traversalState.node.firstChild;
            break;
        case NEXT_SIBLING:
            traversalState.node = traversalState.node.nextSibling;
            break;
        case PARENT_NODE:
            traversalState.node = traversalState.node.parentNode;
            next(fn);
            break;
    }
    if (goDeeper && fn) {
        ++traversalState.i;
        fn();
    }
}

function insertBefore(child, mark, parentNode) {
    const parent = parentNode || mark.parentNode;
    parent.insertBefore(child, mark);
}
function removeNodes(start, end, parentNode) {
    const parent = parentNode || start.parentNode;
    let current = start;
    let next = null;
    while (current && current !== end) {
        next = current.nextSibling;
        parent.removeChild(current);
        current = next;
    }
}
function isFragment(node) {
    return node.nodeType === Node.DOCUMENT_FRAGMENT_NODE;
}
function createMark() {
    return document.createTextNode('');
}
function isMark(node) {
    return node && node.nodeType === Node.TEXT_NODE && !node.textContent;
}
function setupSignalProp(node, key, signal) {
    signal.subscribe((value) => (node[key] = value));
}
function setupAttr(node, key, value) {
    if (typeof value === 'function') {
        setupSignalAttr(node, key, isSignal(value) ? value : computed(value));
        return true;
    }
    if (creatingState.isCreating)
        setupBaseAttr(node, key, value);
}
function setupBaseAttr(node, key, value) {
    if (value === true || value === '') {
        value = '';
    }
    else if (!value) {
        node.removeAttribute(key);
        return;
    }
    node.setAttribute(key, value);
}
function setupSignalAttr(node, key, value) {
    value.subscribe((v) => setupBaseAttr(node, key, v));
}

function createBinding(cb) {
    if (creatingState.isCreating) {
        const mark = createMark();
        creatingState.path += FIRST_CHILD + BINDING + PARENT_NODE;
        creatingState.root.appendChild(mark);
        cb(mark);
        return;
    }
    next();
    const mark = traversalState && traversalState.node;
    cb(mark);
    next();
}

function node(binding) {
    createBinding((mark) => {
        if (creatingState.isCreating) {
            creatingState.setupQueue.push(() => setupNode(binding, mark));
            return;
        }
        setupNode(binding, mark);
    });
}
function setupNode(binding, mark) {
    if (!mark || !binding)
        return;
    if (typeof binding === 'function') {
        if (isSignal(binding)) {
            setupSignalNode(binding, mark);
            return;
        }
        setupSignalNode(computed(binding), mark);
        return;
    }
    insertBefore(binding, mark);
}
function setupSignalNode(binding, mark) {
    let start = mark.previousSibling;
    if (!start) {
        start = createMark();
        insertBefore(start, mark);
    }
    binding.subscribe((node) => {
        removeNodes(start.nextSibling, mark);
        if (node)
            insertBefore(node, mark);
    });
}

function component(fn) {
    let template = null;
    let pathString = '';
    return function (...args) {
        if (!template) {
            const prevSetupQueue = creatingState.setupQueue;
            creatingState.setupQueue = [];
            const data = createComponentData(fn, args);
            pathString = data.pathString;
            template = data.rootNode.cloneNode(true);
            for (let fn of creatingState.setupQueue)
                fn();
            creatingState.setupQueue = prevSetupQueue;
            return data.rootNode;
        }
        const rootNode = template.cloneNode(true);
        setupComponent(fn, args, rootNode, pathString);
        return rootNode;
    };
}
function templateFn(component) {
    return (...args) => node(component(...args));
}
function setupComponent(fn, args, container, pathString) {
    const prevIsCreating = creatingState.isCreating;
    const prevPath = traversalState.path;
    const prevIndex = traversalState.i;
    const prevNode = traversalState.node;
    creatingState.isCreating = false;
    traversalState.path = pathString;
    traversalState.i = 0;
    traversalState.node = container;
    isolate(fn, args);
    creatingState.isCreating = prevIsCreating;
    traversalState.path = prevPath;
    traversalState.i = prevIndex;
    traversalState.node = prevNode;
}
function createComponentData(fn, args) {
    const prevPath = creatingState.path;
    creatingState.path = '';
    const prevRoot = creatingState.root;
    let rootNode = document.createDocumentFragment();
    creatingState.root = rootNode;
    const prevIsCreating = creatingState.isCreating;
    creatingState.isCreating = true;
    isolate(fn, args);
    let pathString = getPathString(creatingState.path);
    creatingState.isCreating = prevIsCreating;
    creatingState.path = prevPath;
    creatingState.root = prevRoot;
    if (rootNode.childNodes.length === 1 && !isMark(rootNode.firstChild)) {
        rootNode = rootNode.firstChild;
        if (pathString[0] === FIRST_CHILD)
            pathString = '_' + pathString.substring(1);
    }
    return { rootNode, pathString };
}
const NEXT_SIBLING_REGEX = new RegExp(PARENT_NODE + FIRST_CHILD, 'g');
const EMPTY_NESTING_REGEX = new RegExp(`${START_CHILDREN}[^${BINDING}${START_CHILDREN}${END_CHILDREN}]*${END_CHILDREN}`, 'g');
const END_CHILDREN_REGEX = new RegExp(END_CHILDREN, 'g');
const EMPTY_TAIL = new RegExp(`[^${BINDING}]+$`, 'g');
const PARENT_NODE_REGEX = new RegExp(`${NEXT_SIBLING}+${PARENT_NODE}`, 'g');
function getPathString(str) {
    str = str.replace(NEXT_SIBLING_REGEX, NEXT_SIBLING);
    let prev = '';
    while (prev !== str) {
        prev = str;
        str = str.replace(EMPTY_NESTING_REGEX, '');
    }
    str = str
        .replace(EMPTY_TAIL, '')
        .replace(END_CHILDREN_REGEX, '')
        .replace(PARENT_NODE_REGEX, PARENT_NODE);
    return str;
}

function classes() {
    const result = fromArray(arguments);
    if (typeof result === 'function') {
        return computed(result);
    }
    return result;
}
function fromObject(obj) {
    let dynamic;
    let result = '';
    for (let key in obj) {
        const value = obj[key];
        if (value) {
            if (typeof value === 'function') {
                if (!dynamic)
                    dynamic = [];
                dynamic.push(key);
                continue;
            }
            if (result)
                result += ' ';
            result += key;
        }
    }
    if (dynamic) {
        return () => {
            let dynamicResult = result;
            for (let key of dynamic) {
                const value = obj[key]();
                if (!value)
                    continue;
                if (dynamicResult)
                    dynamicResult += ' ';
                dynamicResult += key;
            }
            return dynamicResult;
        };
    }
    return result || null;
}
function fromArray(arr) {
    let result = '';
    let dynamic;
    for (let i = 0; i < arr.length; i++) {
        let item = arr[i];
        if (!item)
            continue;
        if (typeof item === 'object') {
            item = Array.isArray(item) ? fromArray(item) : fromObject(item);
        }
        if (item) {
            const itemType = typeof item;
            if (itemType === 'function') {
                if (!dynamic)
                    dynamic = [];
                dynamic.push(item);
            }
            else if (itemType === 'string') {
                if (result)
                    result += ' ';
                result += item;
            }
        }
    }
    if (dynamic) {
        return () => {
            let dynamicResult = result;
            for (let fn of dynamic) {
                const add = fn();
                if (add && typeof add === 'string') {
                    if (dynamicResult)
                        dynamicResult += ' ';
                    dynamicResult += add;
                }
            }
            return dynamicResult || null;
        };
    }
    return result || null;
}

function spec(props, fn) {
    if (!props || (creatingState.isCreating && !creatingState.root))
        return;
    let node;
    let hasBindings = false;
    if (creatingState.isCreating) {
        node = creatingState.root;
    }
    else {
        if (traversalState.path[traversalState.i] !== BINDING)
            return;
        node = traversalState.node;
        next(fn);
    }
    for (let key in props) {
        const reserved = RESERVED[key];
        let value = props[key];
        if (reserved) {
            const result = reserved(node, value);
            if (result)
                hasBindings = true;
            continue;
        }
        key = ALIASES[key] || key;
        if (typeof value === 'function') {
            hasBindings = true;
            if (key[0] === 'o' && key[1] === 'n') {
                node[key] = value;
                continue;
            }
            setupSignalProp(node, key, isSignal(value) ? value : computed(value));
            continue;
        }
        if (creatingState.isCreating)
            node[key] = value;
    }
    if (hasBindings && creatingState.isCreating) {
        creatingState.path += BINDING;
    }
}
const RESERVED = {
    attrs(node, attrs) {
        let hasBindings = false;
        for (let key in attrs) {
            hasBindings = setupAttr(node, key, attrs[key]) || hasBindings;
        }
        return hasBindings;
    },
    class(node, value) {
        if (typeof value === 'object') {
            value = Array.isArray(value) ? fromArray(value) : fromObject(value);
        }
        return setupAttr(node, 'class', value);
    },
    ref(node, cb) {
        cb(node);
        return true;
    },
};
const ALIASES = {
    text: 'textContent',
};

const TEMPLATE_RESULT = {
    // istanbul ignore next
    get __INTERNAL__() {
        return 'Dummy property used for correct type checking only';
    },
};

function h(first, second, third) {
    let props;
    let fn;
    let tag;
    switch (arguments.length) {
        case 1:
            if (typeof first === 'function')
                fn = first;
            else
                tag = first;
            break;
        case 2:
            tag = first;
            if (typeof second === 'function')
                fn = second;
            else
                props = second;
            break;
        case 3:
            tag = first;
            props = second;
            fn = third;
            break;
    }
    if (!tag) {
        fn();
        return TEMPLATE_RESULT;
    }
    if (creatingState.isCreating) {
        const child = document.createElement(tag);
        creatingState.root.appendChild(child);
        creatingState.root = child;
        creatingState.path += FIRST_CHILD;
        spec(props);
        if (fn) {
            creatingState.path += START_CHILDREN;
            fn();
            creatingState.path += END_CHILDREN;
        }
        creatingState.path += PARENT_NODE;
        creatingState.root = creatingState.root.parentNode;
        return TEMPLATE_RESULT;
    }
    next(fn);
    spec(props, fn);
    return TEMPLATE_RESULT;
}

function text(data) {
    let node;
    if (creatingState.isCreating) {
        node = document.createTextNode('_');
        creatingState.root.appendChild(node);
    }
    else {
        next();
        node = traversalState.node;
    }
    if (typeof data === 'function') {
        if (creatingState.isCreating) {
            creatingState.path += FIRST_CHILD + BINDING + PARENT_NODE;
        }
        else
            next();
        setupSignalProp(node, 'textContent', isSignal(data) ? data : computed(data));
        return;
    }
    if (creatingState.isCreating) {
        creatingState.path += FIRST_CHILD + PARENT_NODE;
        node.textContent = data;
    }
}

function list(binding, mapFn) {
    if (creatingState.isCreating && creatingState.root) {
        const mark = createMark();
        creatingState.path += FIRST_CHILD + BINDING + PARENT_NODE;
        creatingState.setupQueue.push(() => setupList(binding, mapFn, mark));
        creatingState.root.appendChild(mark);
        return;
    }
    next();
    setupList(binding, mapFn, traversalState.node);
    next();
}
function setupList(binding, mapFn, mark) {
    if (isSignal(binding)) {
        let start = mark.previousSibling;
        if (!start) {
            start = createMark();
            insertBefore(start, mark);
        }
        let oldArr = [];
        let nodeMap = new Map();
        let cleanupMap = new Map();
        // the algorithm is taken from
        // https://github.com/localvoid/ivi/blob/2c81ead934b9128e092cc2a5ef2d3cabc73cb5dd/packages/ivi/src/vdom/implementation.ts#L1366
        const arrSignal = computed(() => {
            const newArr = binding();
            const parent = mark.parentNode;
            let oldLength = oldArr.length;
            let newLength = newArr.length;
            if (!newLength && !oldLength)
                return;
            const minLength = Math.min(oldLength, newLength);
            let s = 0; // start index
            let a = oldLength - 1; // old array end index
            let b = newLength - 1; // new array end index
            for (let i = 0; i < minLength; ++i) {
                let shouldStop = 0;
                if (oldArr[s] === newArr[s])
                    ++s;
                else
                    ++shouldStop;
                if (oldArr[a] === newArr[b])
                    --a, --b;
                else
                    ++shouldStop;
                if (shouldStop === 2)
                    break;
            }
            // lists are equal
            if (a < 0 && b < 0)
                return;
            // add nodes
            if (s > a) {
                const index = b + 1;
                const endNode = index === newLength ? mark : nodeMap.get(newArr[index]);
                while (s <= b) {
                    insertBefore(createListNode(newArr[s], mapFn, nodeMap, cleanupMap), endNode, parent);
                    ++s;
                }
                oldArr = newArr.slice();
                return;
            }
            // remove nodes
            if (s > b) {
                const endIndex = a + 1;
                const startNode = nodeMap.get(oldArr[s]);
                const endNode = endIndex === oldLength ? mark : nodeMap.get(oldArr[endIndex]);
                removeNodes(startNode, endNode, parent);
                while (s < endIndex) {
                    const el = oldArr[s++];
                    cleanupMap.get(el)();
                    cleanupMap.delete(el);
                    nodeMap.delete(el);
                }
                oldArr = newArr.slice();
                return;
            }
            // reconcile
            const positions = [];
            const elementIndexMap = new Map();
            let removedCount = 0;
            let last = 0;
            let moved = false;
            oldLength = a + 1 - s;
            newLength = b + 1 - s;
            for (let i = 0; i < newLength; ++i) {
                const index = s + i;
                positions[i] = -1;
                elementIndexMap.set(newArr[index], index);
            }
            for (let i = 0; i < oldLength; ++i) {
                const oldIndex = s + i;
                const el = oldArr[oldIndex];
                const newIndex = elementIndexMap.get(el);
                if (newIndex === undefined) {
                    const node = nodeMap.get(el);
                    const end = (node.$lc || node).nextSibling;
                    removeNodes(node, end, parent);
                    cleanupMap.get(el)();
                    cleanupMap.delete(el);
                    nodeMap.delete(el);
                    removedCount++;
                    continue;
                }
                positions[newIndex - s] = oldIndex;
                if (!moved) {
                    if (last > newIndex)
                        moved = true;
                    else
                        last = newIndex;
                }
            }
            if (moved) {
                const lis = getLIS(positions);
                for (let i = 0, j = lis.length - 1; i < newLength; ++i) {
                    const position = positions[newLength - i - 1];
                    const lisPosition = lis[j];
                    if (position === lisPosition) {
                        --j;
                        continue;
                    }
                    const index = b - i;
                    const el = newArr[index];
                    const nextEl = newArr[index + 1];
                    const nextNode = nextEl === undefined //
                        ? mark
                        : nodeMap.get(nextEl);
                    if (position < 0) {
                        insertBefore(createListNode(el, mapFn, nodeMap, cleanupMap), nextNode, parent);
                    }
                    else {
                        const node = nodeMap.get(el);
                        const lastChild = node.$lc;
                        if (lastChild && node !== lastChild) {
                            let current = node;
                            let next;
                            while (1) {
                                next = current.nextSibling;
                                insertBefore(current, nextNode, parent);
                                if (current === lastChild)
                                    break;
                                current = next;
                            }
                        }
                        else {
                            insertBefore(node, nextNode, parent);
                        }
                    }
                }
            }
            else if (oldLength - removedCount !== newLength) {
                for (let i = 0; i < newLength; ++i) {
                    if (positions[newLength - i - 1] !== -1)
                        continue;
                    const index = b - i;
                    const el = newArr[index];
                    const nextEl = newArr[index + 1];
                    const nextNode = nextEl === undefined //
                        ? mark
                        : nodeMap.get(nextEl);
                    insertBefore(createListNode(el, mapFn, nodeMap, cleanupMap), nextNode, parent);
                }
            }
            oldArr = newArr.slice();
        });
        arrSignal.subscribe(NOOP);
        onDeactivate(arrSignal, () => {
            cleanupMap.forEach((cleanup) => cleanup());
            cleanupMap.clear();
            nodeMap.clear();
        });
        return;
    }
    const parent = mark.parentNode;
    for (let el of binding) {
        insertBefore(mapFn(el), mark, parent);
    }
}
function getLIS(arr) {
    const arrLength = arr.length;
    const endIndexes = [];
    const predecessors = [];
    let lisLength = 0;
    for (let i = 0; i < arrLength; ++i) {
        const el = arr[i];
        if (el < 0)
            continue;
        let lo = 1;
        let hi = lisLength + 1;
        while (lo < hi) {
            const mid = lo + (0 | ((hi - lo) / 2));
            if (arr[endIndexes[mid]] > el)
                hi = mid;
            else
                lo = mid + 1;
        }
        predecessors[i] = endIndexes[lo - 1];
        endIndexes[lo] = i;
        if (lo > lisLength)
            lisLength = lo;
    }
    const lis = [];
    let i = lisLength;
    let k = endIndexes[lisLength];
    while (i) {
        lis[--i] = arr[k];
        k = predecessors[k];
    }
    return lis;
}
function createListNode(el, mapFn, nodeMap, cleanupMap) {
    let node;
    const cleanup = collect(() => {
        node = mapFn(el);
    });
    let nodeInMap = node;
    if (isFragment(node)) {
        const firstChild = node.firstChild;
        nodeInMap = createMark();
        if (firstChild)
            node.insertBefore(nodeInMap, firstChild);
        else
            node.appendChild(nodeInMap);
        nodeInMap.$lc = node.lastChild;
    }
    nodeMap.set(el, nodeInMap);
    cleanupMap.set(el, cleanup);
    return node;
}
const NOOP = () => { };

export { classes, component, h, list, node, templateFn, text };
