.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  h1 {
    margin: 0;
  }
}

.dots {
  position: absolute;
}

.dot1 {
  animation: 2s infinite dot1;
}

.dot2 {
  animation: 2s infinite dot2;
}

.dot3 {
  animation: 2s infinite dot3;
}

@keyframes dot1 {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
}

@keyframes dot2 {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  66% {
    opacity: 1;
  }
}

@keyframes dot3 {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
