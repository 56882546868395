@import 'src/client/view/style/constants.scss';

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.img {
  height: 100%;
  flex-shrink: 1;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.caption {
  text-align: center;
  margin-bottom: 0;
  margin-top: 3vh;
  font-size: 3vh;
}
