@import 'src/client/view/style/constants.scss';

.caption {
  margin: 0;
  font-size: 7vh;
  text-align: center;
}

.captionWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}
