@import 'src/client/view/style/constants.scss';

.wrap {
  display: block;
}

.label {
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: $c-foreground-light;
}

.input {
  width: 100%;
  background-color: $c-background;
  border-radius: 6px;
  border-color: $c-foreground-light;
  border-width: 2px;
  height: 44px;
  padding: 4px 8px;
  color: $c-foreground;
  outline: none;
  box-shadow: none;
  border-style: solid;
  margin: 0;
  transition: border-color 0.1s ease-in-out, opacity 0.1s ease-in-out;

  &::placeholder {
    opacity: 0.5;
    color: $c-foreground-light;
  }

  &:focus {
    outline: none;
    border-color: $c-primary;
  }
}
