const NOOP_FN = () => { };

const DEFAULT_CONFIG = {
    logException: /* istanbul ignore next */ (e) => console.error(e),
    _log: NOOP_FN,
};
const config = Object.assign({}, DEFAULT_CONFIG);
function configure(configUpdate) {
    Object.assign(config, configUpdate || DEFAULT_CONFIG);
    if (!config.logger)
        config._log = NOOP_FN;
    else
        config._log = config.logger;
}

const ERROR_NAME = '[SPRED ERROR]';
class CircularDependencyError extends Error {
    constructor() {
        super();
        this.name = ERROR_NAME;
        this.message = 'Circular dependency detected';
    }
}
class StateTypeError extends Error {
    constructor() {
        super();
        this.name = ERROR_NAME;
        this.message =
            'State data must be a plain object or an array or a primitive';
    }
}

let tracking = null;
let scope = null;
let node = null;
let batchLevel = 0;
let status = 0;
const ACTIVATING = 1;
const SCHEDULED = 2;
let queue = [];
let notifications = [];
let version = {};
function isolate(fn, args) {
    const prevTracking = tracking;
    const prevScope = scope;
    const prevStatus = status;
    let result;
    if (tracking)
        scope = tracking;
    status = 0;
    tracking = null;
    if (args)
        result = fn(...args);
    else
        result = fn();
    tracking = prevTracking;
    scope = prevScope;
    status = prevStatus;
    return result;
}
function collect(fn) {
    const prevTracking = tracking;
    const prevScope = scope;
    const prevStatus = status;
    const fakeState = {};
    status = 0;
    scope = fakeState;
    tracking = null;
    fn();
    tracking = prevTracking;
    scope = prevScope;
    status = prevStatus;
    return () => cleanupChildren(fakeState);
}
/**
 * Commits all writable signal updates inside the passed function as a single transaction.
 * @param fn The function with updates.
 */
function batch(fn) {
    const wrapper = config._notificationWrapper;
    batchLevel++;
    fn();
    batchLevel--;
    if (wrapper)
        wrapper(recalc);
    else
        recalc();
}
function update$1(state, value) {
    const wrapper = config._notificationWrapper;
    if (arguments.length > 1) {
        if (typeof value === 'function')
            state.nextValue = value(state.nextValue);
        else
            state.nextValue = value;
    }
    else {
        state.forced = true;
    }
    state.i = queue.push(state) - 1;
    if (wrapper)
        wrapper(recalc);
    else
        recalc();
    return state.nextValue;
}
function subscribe(subscriber, exec = true) {
    const state = this._state;
    const prevStatus = status;
    if (!state.freezed && !state.ft)
        status = ACTIVATING;
    const value = getStateValue(state, true);
    status = prevStatus;
    if (state.freezed) {
        if (exec)
            isolate(() => subscriber(value, true));
        return NOOP_FN;
    }
    let node = createSubscriberNode(state, subscriber);
    ++state.subs;
    if (exec) {
        isolate(() => subscriber(value, true));
    }
    const dispose = () => {
        if (!node)
            return;
        removeNode(node);
        --state.subs;
        node = null;
    };
    const parent = tracking || scope;
    if (parent) {
        if (!parent.children)
            parent.children = [dispose];
        else
            parent.children.push(dispose);
    }
    return dispose;
}
function emitActivateLifecycle(state) {
    logHook(state, 'ACTIVATE');
    if (state.onActivate) {
        state.onActivate(state.value);
    }
}
function emitUpdateLifecycle(state, value) {
    logHook(state, 'UPDATE', value);
    if (state.onUpdate) {
        state.onUpdate(value, state.value);
    }
}
function getFiltered(value, state) {
    const filter = state.filter;
    const prevValue = state.value;
    if (filter)
        return typeof filter === 'function' ? filter(value, prevValue) : true;
    return !Object.is(value, prevValue);
}
/**
 * Immediately calculates the updated values of the signals and notifies their subscribers.
 */
function recalc() {
    if (!queue.length || batchLevel)
        return;
    const q = queue;
    const firstIndex = queue.length;
    const prevStatus = status;
    queue = [];
    version = {};
    status = SCHEDULED;
    ++batchLevel;
    for (let i = 0; i < q.length; i++) {
        const state = q[i];
        if (state.i !== i)
            continue;
        let filtered = true;
        let isWritable = !state.compute;
        if (isWritable) {
            filtered = getFiltered(state.nextValue, state) || state.forced;
            state.forced = false;
            if (filtered) {
                emitUpdateLifecycle(state, state.nextValue);
                state.value = state.nextValue;
            }
        }
        if (filtered) {
            for (let node = state.ft; node !== null; node = node.nt) {
                if (typeof node.t === 'object') {
                    ++node.t.dirty;
                    node.t.i = q.push(node.t) - 1;
                }
                else if (isWritable) {
                    notifications.push(node.t);
                    notifications.push(state.value);
                }
            }
        }
    }
    for (let i = firstIndex; i < q.length; i++) {
        const state = q[i];
        if (state.i !== i || state.version === version || !state.ft)
            continue;
        let value = state.value;
        let filtered = false;
        if (state.dirty) {
            value = calcComputed(state);
            filtered = getFiltered(value, state) || state.hasException;
        }
        if (filtered) {
            if (!state.hasException) {
                emitUpdateLifecycle(state, value);
                state.value = value;
                let subsCount = state.subs;
                for (let node = state.ft; subsCount && node !== null; node = node.nt) {
                    if (typeof node.t === 'function') {
                        notifications.push(node.t);
                        notifications.push(state.value);
                        --subsCount;
                    }
                }
            }
        }
        else {
            for (let node = state.ft; node !== null; node = node.nt) {
                if (typeof node.t === 'object')
                    --node.t.dirty;
            }
        }
        state.version = version;
        state.dirty = 0;
    }
    status = prevStatus;
    for (let i = 0; i < notifications.length; i += 2) {
        notifications[i](notifications[i + 1]);
    }
    notifications = [];
    --batchLevel;
    recalc();
}
function getStateValue(state, notTrackDeps) {
    if (state.compute) {
        if (state.freezed)
            return state.value;
        if (state.tracking) {
            throw new CircularDependencyError();
        }
        let shouldCompute = !state.ft && (status || state.version !== version);
        if (shouldCompute) {
            const value = calcComputed(state, notTrackDeps);
            if (getFiltered(value, state))
                state.value = value;
        }
        state.version = version;
    }
    if (tracking && !notTrackDeps) {
        if (state.hasException && !tracking.hasException) {
            tracking.exception = state.exception;
            tracking.hasException = true;
        }
        if (status) {
            if (node) {
                if (node.s !== state) {
                    if (node.s.ft === node)
                        node.s.ft = node.nt;
                    if (node.s.lt === node)
                        node.s.lt = node.pt;
                    if (node.pt)
                        node.pt.nt = node.nt;
                    if (node.nt)
                        node.nt.pt = node.pt;
                    node.nt = null;
                    node.pt = state.lt;
                    node.s = state;
                    if (state.lt) {
                        state.lt.nt = node;
                    }
                    else {
                        state.ft = node;
                    }
                    state.lt = node;
                }
                node = node.ns;
            }
            else {
                createNode(state, tracking);
            }
        }
    }
    return state.value;
}
function calcComputed(state, logException) {
    const prevTracking = tracking;
    const prevNode = node;
    let value = state.value;
    if (state.children)
        cleanupChildren(state);
    tracking = state;
    node = state.fs;
    state.tracking = true;
    state.hasException = false;
    try {
        value = state.compute(state.value, status === SCHEDULED);
    }
    catch (e) {
        state.exception = e;
        state.hasException = true;
    }
    if (state.hasException) {
        if (state.catch) {
            try {
                value = state.catch(state.exception, state.value);
                state.hasException = false;
                state.exception = undefined;
            }
            catch (e) {
                state.exception = e;
            }
        }
        if (state.hasException) {
            logHook(state, 'EXCEPTION');
            if (state.onException) {
                state.onException(state.exception);
            }
            if (logException || state.subs || (!state.ft && !tracking)) {
                config.logException(state.exception);
            }
        }
    }
    if (status) {
        while (node) {
            removeNode(node);
            node = node.nt;
        }
        if (!state.fs)
            state.freezed = true;
    }
    state.tracking = false;
    tracking = prevTracking;
    node = prevNode;
    return value;
}
function cleanupChildren(state) {
    if (state.children && state.children.length) {
        for (let child of state.children) {
            if (typeof child === 'function')
                child();
            else
                cleanupChildren(child);
        }
        state.children = [];
    }
}
function logHook(state, hook, value) {
    if (!state.name)
        return;
    let payload = state.value;
    if (hook === 'EXCEPTION')
        payload = state.exception;
    else if (hook === 'UPDATE')
        payload = {
            prevValue: state.value,
            value,
        };
    config._log(state.name, hook, payload);
}
function createSubscriberNode(source, target) {
    const node = {
        s: source,
        t: target,
        pt: source.lt,
        nt: null,
    };
    if (source.lt) {
        source.lt.nt = node;
    }
    else {
        source.ft = node;
        emitActivateLifecycle(node.s);
    }
    source.lt = node;
    return node;
}
function createNode(source, target) {
    const node = {
        s: source,
        t: target,
        ps: target.ls,
        ns: null,
        pt: null,
        nt: null,
        stale: false,
        cached: 0,
    };
    if (target.ls) {
        target.ls.ns = node;
    }
    else {
        target.fs = node;
    }
    target.ls = node;
    node.pt = source.lt;
    if (source.lt) {
        source.lt.nt = node;
    }
    else {
        source.ft = node;
        emitActivateLifecycle(node.s);
    }
    source.lt = node;
    return node;
}
function removeNode(node) {
    if (node.t.fs === node)
        node.t.fs = node.ns;
    if (node.t.ls === node)
        node.t.ls = node.ps;
    if (node.ps)
        node.ps.ns = node.ns;
    if (node.ns)
        node.ns.ps = node.ps;
    if (node.s.ft === node)
        node.s.ft = node.nt;
    if (node.s.lt === node)
        node.s.lt = node.pt;
    if (node.pt)
        node.pt.nt = node.nt;
    if (node.nt)
        node.nt.pt = node.pt;
    if (!node.s.ft) {
        const state = node.s;
        logHook(state, 'DEACTIVATE');
        if (state.onDeactivate) {
            state.onDeactivate(state.value);
        }
        for (let node = state.fs; node !== null; node = node.ns) {
            removeNode(node);
        }
    }
}

const signalProto = {
    get() {
        return getStateValue(this._state);
    },
    subscribe,
    sample() {
        return getStateValue(this._state, true);
    },
};

function createSignalState(value, compute) {
    const parent = tracking || scope;
    const state = {
        value,
        compute,
        nextValue: value,
        subs: 0,
        i: 0,
        dirty: 0,
        tracking: false,
        version: null,
        node: null,
        fs: null,
        ls: null,
        ft: null,
        lt: null,
    };
    if (parent) {
        if (!parent.children)
            parent.children = [state];
        else
            parent.children.push(state);
    }
    return state;
}

const writableSignalProto = Object.assign(Object.assign({}, signalProto), { set(value) {
        return update$1(this._state, value);
    },
    notify() {
        return update$1(this._state);
    } });
function writable(value, shouldUpdate) {
    const state = createSignalState(value, undefined);
    const self = function (value) {
        if (!arguments.length)
            return getStateValue(state);
        return update$1(state, value);
    };
    if (shouldUpdate)
        state.filter = shouldUpdate;
    self._state = state;
    self.set = writableSignalProto.set;
    self.get = writableSignalProto.get;
    self.notify = writableSignalProto.notify;
    self.subscribe = writableSignalProto.subscribe;
    self.sample = writableSignalProto.sample;
    return self;
}

/**
 * Sets the activate event listener. The event is emitted at the first subscription or at the first activation of a dependent signal.
 * @param signal Target signal.
 * @param listener Function that listens to the signal activation event.
 */
function onActivate(signal, listener) {
    signal._state.onActivate = listener;
}
/**
 * Sets the deactivate event listener. The event is emitted when there are no subscribers or active dependent signals left.
 * @param signal Target signal.
 * @param listener Function that listens to the signal deactivation event.
 */
function onDeactivate(signal, listener) {
    signal._state.onDeactivate = listener;
}
/**
 * Sets the update event listener. The event is emitted every time the signal value is updated.
 * @param signal Target signal.
 * @param listener Function that listens to the signal update event.
 */
function onUpdate(signal, listener) {
    signal._state.onUpdate = listener;
}
/**
 * Sets the update exception handler. The event is emitted for every unhandled exception in the calculation of the signal value.
 * @param signal Target signal.
 * @param listener Function that listens to the signal exception event.
 */
function onException(signal, listener) {
    signal._state.onException = listener;
}

function isSignal(value) {
    return value._state && value.get;
}
function isWritableSignal(value) {
    return isSignal(value) && value.set;
}
function isStore(value) {
    return isSignal(value) && value.update;
}
function getValue(value) {
    return isSignal(value) ? value() : value;
}
function sampleValue(value) {
    return isSignal(value) ? value.sample() : value;
}

function computed(compute, shouldUpdate, handleException) {
    const getValue = isWritableSignal(compute) ? () => compute() : compute;
    const state = createSignalState(undefined, getValue);
    const self = () => getStateValue(state);
    if (shouldUpdate)
        state.filter = shouldUpdate;
    if (handleException)
        state.catch = handleException;
    self._state = state;
    self.get = signalProto.get;
    self.subscribe = signalProto.subscribe;
    self.sample = signalProto.sample;
    return self;
}

const STOP = {};
let VALUES_CACHE = {};
let counter = 1;
function isPlainObject(obj) {
    const proto = Object.getPrototypeOf(obj);
    return proto && proto.constructor === Object;
}
function isArray(obj) {
    return Array.isArray(obj);
}
function copy(obj) {
    if (isArray(obj))
        return obj.slice();
    if (obj && typeof obj === 'object') {
        if (isPlainObject(obj))
            return Object.assign({}, obj);
        throw new StateTypeError();
    }
    return obj;
}
function getClone(id, state, value) {
    const cached = VALUES_CACHE[id];
    if (cached !== undefined)
        return cached;
    return copy(arguments.length === 3 ? value : state.sample());
}
function clearValuesCache() {
    VALUES_CACHE = {};
}
function update(arg1, arg2) {
    let updateFn;
    if (arguments.length === 2) {
        updateChild(this, arg1, arg2);
        return;
    }
    else {
        updateFn = arg1;
    }
    const setter = this._setter;
    const id = this._id;
    const key = this._key;
    const parent = this._parent;
    let value;
    if (typeof updateFn !== 'function') {
        value = updateFn;
    }
    else {
        const clone = getClone(id, this);
        const next = updateFn(clone);
        if (next === STOP)
            return;
        value = next === undefined ? clone : next;
    }
    VALUES_CACHE[id] = value;
    if (setter) {
        setter(value);
        return;
    }
    parent.update((parentValue) => {
        if (!parentValue)
            return STOP;
        parentValue[key] = value;
    });
}
function updateChild(self, key, arg) {
    self.update((state) => {
        if (!state)
            return STOP;
        const id = self._id + '.' + key;
        let value;
        if (typeof arg !== 'function') {
            value = arg;
        }
        else {
            const clone = getClone(id, undefined, state[key]);
            const next = arg(clone);
            value = next === undefined ? clone : next;
        }
        VALUES_CACHE[id] = value;
        state[key] = value;
    });
}
function select(key) {
    const id = this._id + '.' + key;
    const store = computed(() => {
        const parentValue = this();
        const value = parentValue && parentValue[key];
        if (value === undefined)
            return null;
        return value;
    });
    store._id = id;
    store._key = key;
    store._parent = this;
    store.select = select;
    store.update = update;
    return store;
}
function store(initialState) {
    const id = 'store' + counter++;
    const setter = writable(initialState);
    const store = computed(setter);
    store._setter = setter;
    store._id = id;
    store.select = select;
    store.update = update;
    onUpdate(setter, clearValuesCache);
    return store;
}

/**
 * Calls the passed function immediately and every time the signals it depends on are updated.
 * @param fn A function to watch for.
 * @returns Stop watching function.
 */
function watch(fn) {
    const comp = isSignal(fn) ? fn : computed(fn);
    return comp.subscribe(NOOP_FN);
}

/**
 * Creates a tuple of signal and setter function
 * @param initialValue Initial value of the signal
 * @param shouldUpdate The function that returns a falsy value if the new signal value should be ignored. Use falsy arg value to emit signal values that are not equal to previous vaslue. Use truthy arg value to emit all signal values.
 * @returns A tuple of signal and setter function
 */
function signal(initialValue, shouldUpdate) {
    const source = writable(initialValue, true);
    const signal = computed(source, shouldUpdate);
    function set(payload) {
        if (!arguments.length)
            return source({});
        return source(payload);
    }
    return [signal, set];
}

/**
 * Subscribes the function to updates of the signal value.
 * @param signal Signal.
 * @param subscriber Function that listens to the signal updates.
 * @returns Unsubscribe function.
 */
function on(signal, subscriber) {
    return signal.subscribe(subscriber, false);
}

function named(signal, name) {
    signal._state.name = name;
    return signal;
}

/**
 * Creates an effect from asynchronous function.
 * @param asyncFn Asynchronous function
 * @returns Effect.
 */
function effect(asyncFn, name) {
    let counter = 0;
    let current = -1;
    const _status = writable('pristine');
    const _exception = writable(undefined, true);
    const _data = writable(undefined, true);
    const _aborted = writable();
    const _args = writable(undefined, true);
    const lastStatus = computed(_status, (status) => status !== 'pending');
    lastStatus.subscribe(NOOP_FN);
    const status = computed(() => {
        const value = _status();
        return {
            value,
            pristine: value === 'pristine',
            pending: value === 'pending',
            fulfilled: value === 'fulfilled',
            rejected: value === 'rejected',
            settled: value === 'fulfilled' || value === 'rejected',
        };
    });
    const exception = computed(_exception, true);
    const done = computed(() => {
        const data = _data();
        const exception = _exception();
        switch (_status.sample()) {
            case 'pristine':
            case 'fulfilled':
                return data;
            case 'rejected':
                return exception;
        }
    }, true);
    const data = computed(_data, true);
    const aborted = computed(_aborted, true);
    const args = computed(_args, true);
    const abort = () => {
        if (!status.sample().pending)
            return;
        logEvent(name, 'ABORT');
        batch(() => {
            _status(lastStatus());
            _aborted({});
        });
        counter++;
    };
    const reset = () => {
        if (status.sample().pristine)
            return;
        logEvent(name, 'RESET');
        batch(() => {
            if (status().pending)
                _aborted({});
            _status('pristine');
        });
        counter++;
    };
    const exec = (id, ...args) => {
        return asyncFn(...args)
            .then((res) => {
            current = id;
            return res;
        })
            .catch((e) => {
            current = id;
            throw e;
        });
    };
    const call = (...args) => {
        logEvent(name, 'CALL', args);
        if (_status.sample() === 'pending') {
            _aborted({});
        }
        batch(() => {
            _args(args);
            _status('pending');
        });
        return exec(++counter, ...args)
            .then((v) => {
            if (current !== counter)
                return v;
            batch(() => {
                _data(v);
                _status('fulfilled');
            });
            return v;
        })
            .catch((e) => {
            if (current !== counter)
                throw e;
            batch(() => {
                _exception(e);
                _status('rejected');
            });
            throw e;
        });
    };
    if (name) {
        named(data, name + '.data');
        named(exception, name + '.exception');
        named(done, name + '.done');
        named(aborted, name + '.aborted');
        named(args, name + '.args');
        named(status, name + '.status');
    }
    return {
        data,
        exception,
        done,
        aborted,
        args,
        status,
        call,
        abort,
        reset,
    };
}
function logEvent(effectName, eventName, payload) {
    if (!effectName)
        return;
    config._log(effectName, eventName, payload);
}

function createLogger(opts) {
    const include = opts && opts.include;
    const exclude = opts && opts.exclude;
    function log(unitName, ...rest) {
        console.log(`%c[${unitName}]%c`, 'font-weight: bold', 'font-weight: normal', ...rest);
    }
    function createLogFn() {
        return (unitName, ...rest) => {
            let shouldLog = true;
            if (include)
                shouldLog = include.includes(rest[0]);
            if (exclude)
                shouldLog = shouldLog && !exclude.includes(rest[0]);
            if (shouldLog)
                log(unitName, ...rest);
        };
    }
    return createLogFn();
}

export { batch, collect, computed, configure, createLogger, effect, getValue, isSignal, isStore, isWritableSignal, isolate, named, on, onActivate, onDeactivate, onException, onUpdate, sampleValue, signal, store, watch, writable };
